<template>
  <v-layout justify-center align-center>
    <v-row dense v-if="items">
      <v-col v-for="(item, i) in items" :key="i" cols="6">
        <v-card
          :style="'background-color: ' + colorLuminance(getRandomColor(), -0.3)"
          @click="
            $router.push({
              name: 'quiz-questions',
              params: { id: item.id },
            })
          "
          dark
        >
          <div class="d-flex flex-no-wrap justify-space-between">
            <div v-if="$store.getters.getCurrLocale == 'en'">
              <v-card-text class="subtitle-1">
                <span style="text-shadow: 2px 1px 0px #000">{{
                  item.title
                }}</span>
              </v-card-text>
            </div>
            <div v-else>
              <v-card-text class="subtitle-1">
                <span style="text-shadow: 2px 1px 0px #000">{{
                  item.title_hi ? item.title_hi : item.title
                }}</span>
              </v-card-text>
            </div>

            <v-avatar height="100" tile> </v-avatar>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12">
        <!-- <v-pagination
          v-if="pagination.lastPage > 1"
          v-model="pagination.page"
          :length="pagination.lastPage"
        ></v-pagination> -->
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import { axios } from "@/plugins/axios";

export default {
  name: "tests",
  components: {},
  data() {
    return {
      items: [],
    };
  },
  computed: {},
  methods: {
    getRandomColor1() {
      // var r = () => (Math.random() * 256) >> 0;
      // var color = `rgb(${r()}, ${r()}, ${r()})`;
      // return color;
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    colorLuminance(hex, lum) {
      // validate hex string
      hex = String(hex).replace(/[^0-9a-f]/gi, "");
      if (hex.length < 6) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }
      lum = lum || 0;

      // convert to decimal and change luminosity
      var rgb = "#",
        c,
        i;
      for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16);
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
        rgb += ("00" + c).substr(c.length);
      }

      return rgb;
    },
    fetchItems() {
      axios
        .get("prep/tags/public")
        .then((res) => {
          this.items = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.fetchItems();
  },
};
</script>

